import { RouteComponentProps } from '@reach/router';
import { Link } from 'gatsby';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Helmet } from 'react-helmet';
import { staticStyles } from 'styles';

import { Container } from '../components/Container';

const TermsOfConditionsPage: React.FC<RouteComponentProps> = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Helmet
        title={`Wienerberger - ${formatMessage({
          id: 'terms-and-conditions.title',
        })}`}
      />
      <Container className={staticStyles.staticWrapper}>
        <h1 css={staticStyles.title}>
          <FormattedMessage id="terms-and-conditions.title" />
        </h1>
        <h2 css={staticStyles.boldText}>
          <FormattedMessage id="terms-and-conditions.useful-to-know.title" />
        </h2>
        <p css={staticStyles.text}>
          <FormattedMessage id="terms-and-conditions.useful-to-know.paragraph.1" />
        </p>
        <p css={staticStyles.text}>
          <FormattedMessage id="terms-and-conditions.useful-to-know.paragraph.2" />
        </p>
        <p css={staticStyles.text}>
          <FormattedMessage id="terms-and-conditions.useful-to-know.paragraph.3" />{' '}
          <Link to="/impresum" css={staticStyles.linkText}>
            <FormattedMessage id="shared.impressum" />
          </Link>
        </p>
        <p css={staticStyles.text}>
          <FormattedMessage id="terms-and-conditions.useful-to-know.paragraph.4" />{' '}
          <Link to="/pravila-privatnosti" css={staticStyles.linkText}>
            <FormattedMessage id="shared.privacy-statement" />
          </Link>
        </p>
        <h2 css={staticStyles.boldText}>
          <FormattedMessage id="terms-and-conditions.responsibility.title" />
        </h2>
        <p css={staticStyles.text}>
          <FormattedMessage id="terms-and-conditions.responsibility.paragraph.1" />
        </p>
        <p css={staticStyles.text}>
          <FormattedMessage id="terms-and-conditions.responsibility.paragraph.2" />
        </p>
        <h2 css={staticStyles.boldText}>
          <FormattedMessage id="terms-and-conditions.copyright-info.title" />
        </h2>
        <p css={staticStyles.text}>
          <FormattedMessage id="terms-and-conditions.copyright-info.paragraph.1" />
        </p>
      </Container>
    </>
  );
};

export default TermsOfConditionsPage;
